import {
  defineNuxtRouteMiddleware,
  navigateTo,
  useRuntimeConfig,
} from "#imports"

export default defineNuxtRouteMiddleware(() => {
  const config = useRuntimeConfig()

  if (config.public.env !== "local-dev" && config.public.env !== "demo") {
    return navigateTo("/")
  }
})
